import React from 'react'
import ContactUs from '../HomeScreen/Contectus'
import ContactForm from './ContactUsForm'
const Contactus = () => {
  return (
    <div>
      <ContactForm/>
      <ContactUs/>

    </div>
  )
}

export default Contactus
