import React from 'react'
import Welcome from './welcome';
import DecoreH from './DecoreH';
import Popular from './Popular';
import Feedback from '../../components/Feedback';
import Banner from './Banner';
import ContactUs from './Contectus';
import Service from './Service';

export default function MainHomecustomer() {
  return (
    <>
           <Welcome/>
           <DecoreH/>
           <Popular/>
           <Feedback/>
           <Banner/>
           <Service/>
           <ContactUs/>
   
    </>
  
   
  )
}
