import React from 'react'
import Products from './Products'
import Banner from './Banner'
const Store = () => {
  return (
    <>
      <Banner/>
      <Products/>
    
          </>
  )
}

export default Store
