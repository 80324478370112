import React from 'react'
import Navbar from './Components/Navbar'
import SellerRoutesFile from './SellerRoutes/SellerRoutesFile'
export default function SellerMain() {
  return (
    <>
     <Navbar/>
     <SellerRoutesFile/> 
    </>
  )
}
