import React from 'react'
import HomeBanner from './HomeBanner'
import Graph from './Graph'
function Home() {
  return (
    <>
        <HomeBanner/>
    <Graph/>
      
    </>
  )
}

export default Home
