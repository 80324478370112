
import './index.css'
import RouteMainFile from './Mainroute/RouteMainFile'
import './App.css'; // import your CSS file

const App = () => {
  return (
    <div className='app-container'>
{/* <CustomerMain/>    */}
{/* <HomeMain/> */}
{/* <LoginScreen/> */}
{/* <ProductForm/> */}
{/* <MainScreen/> */}
<RouteMainFile/>
{/* <SellerMain/> */}
 </div>
  )
}

export default App



