import React from 'react'
import ProductForm from './ProductFrom/ProductForm'

const AddProduct = () => {
  return (
    <div>
      <ProductForm/>
    </div>
  )
}


export default AddProduct
